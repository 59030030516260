import { createSlice } from '@reduxjs/toolkit';
import { apiCall } from "../../../../../api";
import { toast } from "react-toastify";

const slice = createSlice({
    name: 'maxsulotlar',
    initialState: {
        maxsulotlar: null,
        productTableSearch: null,
        productSearch: null,
        productRemain: null,
        comboGetDtoList: null,
        current: false,
        active: false,
        productFilter: null,
        message: '',
        excel: '',
        lessproducts: null,
        lesspage: null,
        getBoolean: false,
        saveBoolean: false,
        deleteBoolean: false,
        deleteCurrent: false,
        productType: null,
        productPurchase: null,
        productTrade: null,
        productProduction: null,
        productionWaste: null,
        productQuantityByDate: null,
        productOneWorkingQuantity: null,
        product: {},
        productTypePriceGetDtoList: null,
        productOneOtherInfo: null,
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.maxsulotlar = action.payload.object;
            } else {
                state.maxsulotlar = null;
                state.message = action.payload.message;
            }
            state.getBoolean = !state.getBoolean;
            state.saveBoolean = false;
        },
        getFromOneProductQuantity: (state, action) => {
            if (action.payload.success) {
                state.productOneWorkingQuantity = action.payload.object;
            } else {
                state.productOneWorkingQuantity = null;
                state.message = action.payload.message;
            }
            state.getBoolean = !state.getBoolean;
            state.saveBoolean = false;
        },
        getFromOneOtherInfo: (state, action) => {
            if (action.payload.success) {
                state.productOneOtherInfo = action.payload.object;
            } else {
                state.productOneOtherInfo = null;
                state.message = action.payload.message;
            }
            state.getBoolean = !state.getBoolean;
            state.saveBoolean = false;
        },
        getFromForTable: (state, action) => {
            if (action.payload.success) {
                state.productTableSearch = action.payload.object;
            } else {
                state.productTableSearch = null;
                state.message = action.payload.message;
            }
            state.getBoolean = !state.getBoolean;
            state.saveBoolean = false;
        },
        getFromBarcode: (state, action) => {
            if (action.payload.success) {
                state.productSearch = action.payload.object;
            } else {
                state.productSearch = null;
            }
            state.getBoolean = !state.getBoolean;
            state.saveBoolean = false;
        },
        getProductTypeBranch: (state, action) => {
            if (action.payload.success) {
                state.productType = action.payload.object;
            } else {
                state.productType = null;
            }
        },
        getFromPurchase: (state, action) => {
            if (action.payload.success) {
                state.productPurchase = action.payload.object;
            } else {
                state.productPurchase = null;
            }
        },
        getFromWaste: (state, action) => {
            if (action.payload.success) {
                state.productionWaste = action.payload.object;
            } else {
                state.productionWaste = null;
            }
        },
        getFromTrade: (state, action) => {
            if (action.payload.success) {
                state.productTrade = action.payload.object;
            } else {
                state.productTrade = null;
            }
        },
        getFromProduction: (state, action) => {
            if (action.payload.success) {
                state.productProduction = action.payload.object;
            } else {
                state.productProduction = null;
            }
        },
        getProductQuantityHistory: (state, action) => {
            if (action.payload.success) {
                state.productQuantityByDate = action.payload.object;
            } else {
                state.productQuantityByDate = null;
                state.message = action.payload.message;
            }
            state.getBoolean = !state.getBoolean;
        },
        getProductRemainHistory: (state, action) => {
            if (action.payload.success) {
                state.productRemain = action.payload.object;
            } else {
                state.productRemain = null;
                state.message = action.payload.message;
            }
            state.getBoolean = !state.getBoolean;
        },
        getFromById: (state, action) => {
            if (action.payload.success) {
                state.product = action.payload.object.product;
                state.productTypePriceGetDtoList = action.payload.object.productTypePriceGetDtoList;
                state.productOneOtherInfo = action.payload.object;
                state.comboGetDtoList = action.payload.object.comboGetDtoList;
                state.active = !state.active;
            } else {
                state.message = action.payload.message;
            }
            state.getBoolean = !state.getBoolean;
        },
        getFromLess: (state, action) => {
            if (action.payload.success) {
                state.lessproducts = action.payload.object?.getLessProduct;
                state.lesspage = action.payload.object;
                state.current = !state.current;
            } else {
                state.message = action.payload.message;
            }
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                state.saveBoolean = true;
                toast.success('Mahsulot qo`shildi');
                state.current = !state.current;
            } else {
                toast.error(action.payload.object);
                state.current = !state.current;
            }
        },
        savefromByExcel: (state, action) => {
            if (action.payload.success) {
                state.saveBoolean = true;
                toast.success('Excel Jadval qo`shildi');
            } else {
                toast.error(action.payload.message);
            }
            state.current = !state.current;
        },
        editfrom: (state, action) => {
            if (action.payload.success) {
                state.saveBoolean = true;
                toast.success('Mahsulot tahrirlandi');
            } else {
                toast.error(action.payload.message);
            }
            state.current = !state.current;
        },
        deactivateProduct: (state, action) => {
            if (action.payload.success) {
                toast.success('Mahsulot o`chirildi');
                state.productOneOtherInfo.typeList = state.productOneOtherInfo.typeList.filter(item => item.productTypePriceId !== action.payload.id);
            } else {
                toast.error(action.payload.message);
            }
            state.current = !state.current;
        },
        deletefromByIds: (state, action) => {
            if (action.payload.success) {
                toast.success('Tanlangan mahsulotlar o`chirildi');
            } else {
                toast.error(action.payload.message);
            }
            state.deleteCurrent = !state.deleteCurrent;
            state.deleteBoolean = true;
        },
        getFromExcel: (state, action) => {
            if (action.payload.success) {
                state.current = !state.current;
                state.excel = action.payload;
            } else {
                toast.error(action.payload.message);
            }
            state.getBoolean = true;
        },
        getFromFilter: (state, action) => {
            if (action.payload.success) {
                state.productFilter = action.payload.object;
            } else {
                state.productFilter = null;
                state.message = action.payload.message;
            }
            state.getBoolean = !state.getBoolean;
            state.saveBoolean = false;
        },
        deactivateProductSuccess: (state, action) => {
            toast.success('Mahsulot o‘chirildi');
            state.productOneOtherInfo.typeList = state.productOneOtherInfo.typeList.filter(item => item.productTypePriceId !== action.payload.id);
        },
        deactivateProductFailed: (state, action) => {
            toast.error(action.payload.message);
        },
    }
});

export const getProductType = (data) => apiCall({
    url: '/product/get-by-branch-for-purchase-trade/' + data,
    method: 'get',
    onSuccess: slice.actions.getProductTypeBranch.type,
    onFail: slice.actions.getProductTypeBranch.type,
});

export const getOneProductAbout = (data) => apiCall({
    url: '/productAbout/amount/' + data.productId,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getFromOneProductQuantity.type,
    onFail: slice.actions.getFromOneProductQuantity.type,
});

export const getOneProductOtherInfo = (data) => apiCall({
    url: '/productAbout/' + data.productId,
    method: 'get',
    params: { branchId: data.branchId },
    onSuccess: slice.actions.getFromOneOtherInfo.type,
    onFail: slice.actions.getFromOneOtherInfo.type,
});

export const getProductPurchase = (data) => apiCall({
    url: `/product/get-purchase-product/${data.branchId}?productId=${data.productId}`,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getFromPurchase.type,
    onFail: slice.actions.getFromPurchase.type,
});

export const getProductionWaste = (data) => apiCall({
    url: `/product/get-content-production-product/${data.branchId}?productId=${data.productId}`,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getFromWaste.type,
    onFail: slice.actions.getFromWaste.type,
});

export const getProductTrade = (data) => apiCall({
    url: `/product/get-trade-product/${data.branchId}?productId=${data.productId}`,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getFromTrade.type,
    onFail: slice.actions.getFromTrade.type,
});

export const getProductProduction = (data) => apiCall({
    url: `/product/get-production-product/${data.branchId}?productId=${data.productId}`,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getFromProduction.type,
    onFail: slice.actions.getFromProduction.type,
});

export const getMaxsulotRuyxati = (data) => apiCall({
    url: '/product/get-by-business/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const getProductFilter = (data) => apiCall({
    url: `/product/get-by-business/${data.businessId}?${data.branchId ? `branch_id=${data.branchId}` : ''}${data.brandId ? `&brand_id=${data.brandId}` : ''}`,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getFromFilter.type,
    onFail: slice.actions.getFromFilter.type
});

export const getProductTableSearch = (data) => apiCall({
    url: `/product/get-by-business-pageable/${data.businessId}`,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getFromForTable.type,
    onFail: slice.actions.getFromForTable.type
});



export const getProductHistoryByDate = (data) => apiCall({
    url: '/productHistory/' + data.branchId,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getProductQuantityHistory.type,
    onFail: slice.actions.getProductQuantityHistory.type
});

export const getProductRemainHistory = (data) => apiCall({
    url: '/productHistory/amount/' + data.branchId,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getProductRemainHistory.type,
    onFail: slice.actions.getProductRemainHistory.type
});

export const getBarcodeAndName = (data) => apiCall({
    url: '/product/search/' + data.branchId,
    method: 'get',
    params: {
        name: data.name
    },
    onSuccess: slice.actions.getFromBarcode.type,
    onFail: slice.actions.getFromBarcode.type
});

export const saveMaxsulotRuyxati = (data) => apiCall({
    url: '/product',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const saveProductByExcelFile = (data) => apiCall({
    url: `/excel/upload`,
    method: 'post',
    data: data.file,
    params: data.params,
    contentType: '"multipart/form-data"',
    onSuccess: slice.actions.savefromByExcel.type,
    onFail: slice.actions.savefromByExcel.type
})

export const editMaxsulotRuyxati = (data) => apiCall({
    url: '/product/' + data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteMaxsulotRuyxati = (data) => apiCall({
    url: '/product/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})


export const getMaxsulotById = (data) => apiCall({
    url: '/product/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromById.type,
    onFail: slice.actions.getFromById.type
});

export const deactivateProduct = (id) => apiCall({
    url: `/product/deactivate/${id}`,
    method: 'put',
    onSuccess: slice.actions.deactivateProductSuccess.type,
    onFail: slice.actions.deactivateProductFailed.type
});

export const deleteMaxsulotRuyxatiByIds = (data) => apiCall({
    url: '/product/delete-few',
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefromByIds.type,
    onFail: slice.actions.deletefromByIds.type
})

export const getProductExcel = (id) => apiCall({
    url: '/excel/export-to-excel/' + id,
    method: 'get',
    onSuccess: slice.actions.getFromExcel.type,
    onFail: slice.actions.getFromExcel.type
})

export const getFromLess = (id) => apiCall({
    url: `/warehouse/${id.businessId}?branchId=${id.branchId}&page=${id.page}&size=5`,
    method: 'get',
    onSuccess: slice.actions.getFromLess.type,
    onFail: slice.actions.getFromLess.type
})

export default slice.reducer;
